import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroPages from "../components/heroPages"
import Image from "../components/imagesComponent/privacy"

export default function Privacy({ data = {} }) {
  const { barText } = data.takeshape.getTopBar
  const { title, subtitle, contentHtml } = data.takeshape.getPrivacyPolicy
  const footer = data.takeshape.getContactUsPage
  return (
    <Layout footer={footer} barText={barText}>
      <SEO title="About" />
      <HeroPages title={title} text={subtitle} image={<Image />} />
      <div className="container mx-auto px-5 md:mt-16 md:mb-24 mb-10 leading-loose">
        <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($locale: String) {
    takeshape {
      getPrivacyPolicy(locale: $locale) {
        title
        subtitle
        contentHtml
      }
      getContactUsPage {
        phones {
          phone
        }
        facebook
        instagram
      }
      getTopBar(locale: $locale) {
        barText
      }
    }
  }
`
